import {createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw} from 'vue-router'

const ifNotAuthenticated = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (!localStorage.getItem('voluntup_token')) {
        next()
        return
    }
    next('/')
}

const ifAuthenticated = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (localStorage.getItem('voluntup_token')) {
        next()
        return
    }
    next('/login')
}

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
        beforeEnter: ifNotAuthenticated
    },
    {
        path: '/',
        name: 'main',
        component: () => import('../MainLayout.vue'),
        beforeEnter: ifAuthenticated,
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
            },
            {
                path: '/activity',
                name: 'activity_wizard',
                component: () => import(/* webpackChunkName: "wizard" */ '../views/WizardView.vue')
            },
            {
                path: '/activity/:id/volunteers',
                name: 'activity_volunteers',
                component: () => import(/* webpackChunkName: "home" */ '../views/VolunteerListView.vue')
            },
            {
                path: '/activity/:slug',
                name: 'activity_wizard_edit',
                component: () => import(/* webpackChunkName: "wizard" */ '../views/WizardView.vue')
            }
        ]
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
