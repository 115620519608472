import {defineStore} from 'pinia'

export const useAppStore = defineStore('appStore', {
    state: () => {
        return {
            loader: false
        }
    },
    actions: {
        startLoader() {
            this.loader = true
        },
        stopLoader() {
            this.loader = false
        }
    }
})
